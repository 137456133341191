import { Handover, HandoverTableData, Preparation } from '../apollo/queries/getHandoverExtended';
import { OrderContract } from '../apollo/queries/getOrder';
import { AdditionalUpgradesStatuses, PreparationsIds } from '../enums/VehicleRequirements';
import { displayText } from './stringHelper';
import {
  checkIcon,
  DEFAULT_CELL,
  INSTALLATION_CONFIRMED_CELL,
  INSTALLED_AT_FACTORY_GREEN_CELL,
  INSTALLED_AT_FACTORY_YELLOW_CELL,
  NOT_ORDERED_CELL,
  ORDERED_CELL
} from '~enums/Cells';
import { InternalColors } from '@polestar/component-warehouse-react/internal';
import { SingleOptionsStatuses } from '~enums';

export const CABLE_CODES_PART_NUMBERS = [
  { code: 'A01446', partNumber: '32324604' },
  { code: 'A01537', partNumber: '32257286' },
  { code: 'A01518', partNumber: '32257184' }
];

export const NEEDS_INSTALLATION_CODES = [
  ...CABLE_CODES_PART_NUMBERS.map(x => x.code),
  'A01302',
  '001248',
  '001292',
  '001227',
  '001226'
];

export const DisplayForOrderingStatuses = [
  AdditionalUpgradesStatuses.TO_ORDER,
  AdditionalUpgradesStatuses.ORDERED,
  AdditionalUpgradesStatuses.ORDERED_INSTALLED
];

export const DisplayForInstallationStatuses = [
  AdditionalUpgradesStatuses.TO_ORDER,
  AdditionalUpgradesStatuses.ORDERED,
  AdditionalUpgradesStatuses.ORDERED_INSTALLED,
  AdditionalUpgradesStatuses.TO_INSTALL,
  AdditionalUpgradesStatuses.INSTALLED
];

export const InstalledAdditionalUpgradesStatuses = [
  AdditionalUpgradesStatuses.FACTORY_INSTALLED,
  AdditionalUpgradesStatuses.INSTALLED,
  AdditionalUpgradesStatuses.ORDERED_INSTALLED
];

export const getPartNumberForAdditionalUpgradeCode = (code: string | null | undefined) => {
  return displayText(CABLE_CODES_PART_NUMBERS.find(item => item.code === code)?.partNumber);
};

export const getAllAdditionalUpgrades = (fullOrder: OrderContract | undefined) => {
  return (
    fullOrder?.data?.orderlines
      ?.map(line => line.car_configuration?.additional_upgrades)
      .filter(line => line)
      .flat() ?? []
  );
};
export const getCodesIncludedInVehicleRequirement = (handover: Handover) => {
  return (
    handover.vehiclePreparationRequirements?.preparations
      .filter(prep => prep.includes(PreparationsIds.ADDITIONAL_UPGRADES_ORDERED_PREFIX))
      .map(prep => prep.replace(PreparationsIds.ADDITIONAL_UPGRADES_ORDERED_PREFIX, '')) ?? []
  );
};

export type AdditionalUpgradesItems = {
  code: string;
  name: string;
  status: AdditionalUpgradesStatuses;
};

export const getAdditionalUpgradeStatus = (code: string, handover: Handover) => {
  const needsOrdering = getCodesIncludedInVehicleRequirement(handover).includes(code);
  const needsInstallation = NEEDS_INSTALLATION_CODES.includes(code);

  if (!needsOrdering && !needsInstallation) {
    return AdditionalUpgradesStatuses.FACTORY_INSTALLED;
  }

  if (!needsOrdering && needsInstallation) {
    const hasBeenInstalled = Boolean(
      handover.preparations?.find(
        x => x.preparationId === `${PreparationsIds.ADDITIONAL_UPGRADES_DELIVERED_PREFIX}${code}`
      )
    );
    return hasBeenInstalled
      ? AdditionalUpgradesStatuses.INSTALLED
      : AdditionalUpgradesStatuses.TO_INSTALL;
  }
  if (needsOrdering) {
    const hasBeenOrdered = Boolean(
      handover.preparations?.find(
        x => x.preparationId === `${PreparationsIds.ADDITIONAL_UPGRADES_ORDERED_PREFIX}${code}`
      )
    );
    const hasBeenInstalled = Boolean(
      handover.preparations?.find(
        x => x.preparationId === `${PreparationsIds.ADDITIONAL_UPGRADES_DELIVERED_PREFIX}${code}`
      )
    );

    if (hasBeenInstalled) {
      return AdditionalUpgradesStatuses.ORDERED_INSTALLED;
    } else
      return hasBeenOrdered
        ? AdditionalUpgradesStatuses.ORDERED
        : AdditionalUpgradesStatuses.TO_ORDER;
  }
};

export const getAdditionalUpgradesWithStatus = (
  fullOrder: OrderContract | undefined,
  handover: Handover
) => {
  const upgradesItems = getAllAdditionalUpgrades(fullOrder);
  let upgradesWithStatus: Array<AdditionalUpgradesItems> = [];
  upgradesItems &&
    upgradesItems.length > 0 &&
    upgradesItems.forEach(item =>
      upgradesWithStatus.push({
        code: item?.code ?? '',
        name: item?.name ?? '',
        status:
          getAdditionalUpgradeStatus(item?.code ?? '', handover) ??
          AdditionalUpgradesStatuses.FACTORY_INSTALLED
      })
    );

  return upgradesWithStatus;
};

export const getInstalledAdditionalUpgrades = (fullOrder: OrderContract | undefined) => {
  return getAllAdditionalUpgrades(fullOrder).filter(
    option => !NEEDS_INSTALLATION_CODES.includes(option?.code ?? '')
  );
};

export const isUpgradeItemOrdered = (
  code: string,
  preparations: Array<Preparation> | undefined | null
) => {
  return (
    preparations?.find(
      x => x.preparationId === `${PreparationsIds.ADDITIONAL_UPGRADES_ORDERED_PREFIX}${code}`
    ) !== undefined
  );
};

export const getCellFromAdditionalUpgradeStatus = (status: string) => {
  switch (status) {
    case AdditionalUpgradesStatuses.TO_ORDER:
      return NOT_ORDERED_CELL;
    case AdditionalUpgradesStatuses.ORDERED:
      return ORDERED_CELL;
    case AdditionalUpgradesStatuses.FACTORY_INSTALLED:
      return INSTALLED_AT_FACTORY_GREEN_CELL;
    case AdditionalUpgradesStatuses.TO_INSTALL:
      return INSTALLED_AT_FACTORY_YELLOW_CELL;
    case AdditionalUpgradesStatuses.INSTALLED:
    case AdditionalUpgradesStatuses.ORDERED_INSTALLED:
      return INSTALLATION_CONFIRMED_CELL;
    default:
      return DEFAULT_CELL;
  }
};

export const getAdditionalUpgradesStatus = (handover: HandoverTableData) => {
  const additionalUpgrades = handover.orderData?.additionalUpgrades;

  const upgradesWithStatus = additionalUpgrades?.map(upgrade => {
    const status = getAdditionalUpgradeStatus(upgrade.code ?? '', handover);
    return {
      code: upgrade.code,
      status
    };
  });

  if (
    upgradesWithStatus?.every(upgrade => upgrade.status === AdditionalUpgradesStatuses.TO_ORDER)
  ) {
    return SingleOptionsStatuses.NOT_ORDERED;
  } else if (
    upgradesWithStatus?.every(
      upgrade =>
        upgrade.status === AdditionalUpgradesStatuses.FACTORY_INSTALLED ||
        upgrade.status === AdditionalUpgradesStatuses.INSTALLED ||
        upgrade.status === AdditionalUpgradesStatuses.ORDERED_INSTALLED
    )
  ) {
    return SingleOptionsStatuses.COMPLETED;
  } else if (
    upgradesWithStatus?.some(upgrade => upgrade.status === AdditionalUpgradesStatuses.TO_ORDER)
  ) {
    return SingleOptionsStatuses.IN_PROGRESS;
  } else {
    return SingleOptionsStatuses.ORDERED;
  }
};
